
.map-sheet-actions {
    position: absolute;
    bottom: 12px;
    right: 10px;
    z-index: 2;
    overflow: hidden;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
