
.crown-space {
    height: 70px;
}
.product-item__inner {
    position: relative;
    .product-crown {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    > a {
        position: relative;
        .product-rank {
            position: absolute;
            top: 0;
            left: 0;
            width: 32px;
            &--crown {
                width: 36px;
                transform: translate(-4px, -4px);
            }
        }
    }
}
@media (min-width: 1024px) {
    .product-item__inner {
        > a {
            .product-rank {
                width: 32px;
                &--crown {
                    width: 40px;
                    transform: translate(-10px, -10px);
                }
            }
        }
    }
}
