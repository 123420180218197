
.mobile-sidebar__list {
    .v-list-item {
        padding: 0 var(--container-gutter);
    }
    ::v-deep() {
        .v-list-group__header {
            padding: 0 var(--container-gutter);
        }
    }
    > .v-list-item {
        min-height: 72px;
    }
    > .v-list-group {
        ::v-deep() {
            > .v-list-group__header {
                min-height: 72px;
            }
            > .v-list-group__items {
                > .v-list-item {
                    padding-left: calc(var(--container-gutter) * 2);
                }
                > .v-list-group--sub-group {
                    > .v-list-group__items {
                        > .v-list-item {
                            padding-left: calc(var(--container-gutter) * 2);
                        }
                    }
                    > .v-list-group__header {
                        padding-left: calc(var(--container-gutter) * 2);
                        > .v-list-item__title {
                            font-weight: 700;
                            font-size: var(--tit-font-size-xs);
                        }
                        > .v-list-item__icon {
                            order: 2;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}
