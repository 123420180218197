
.main-header-slide {
    max-width: calc(100% - 48px);
    overflow: visible;
    .swiper-slide {
        .v-card {
            position: relative;
        }
        &__bg {
            background-position: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            z-index: -1;
        }
        &__txt {
            height: 100%;
            color: #fff;
            font-size: 20px;
            font-weight: bold;
            padding: 32px 26px;
        }
    }
}
