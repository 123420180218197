
// .theme--light.v-btn-toggle.notification-btn-toggle {
//     .v-btn {
//         margin: 0 4px;
//         border-left-width: 1px !important;
//         &:first-child {
//             margin-left: 0;
//         }
//         &:last-child {
//             margin-right: 0;
//         }
//         &:not(.v-btn--active) {
//             background-color: #fff !important;
//             border-color: var(--v-primary-base) !important;
//             color: var(--v-primary-base) !important;
//         }
//     }
// }

// .theme--light .notification-btn-toggle .v-slide-item {
//     margin: 0 4px;
// }

// .theme--light .notification-btn-toggle .v-btn {
//     margin: 0 4px;
//     border-left-width: 1px !important;
// }

// .theme--light .notification-btn-toggle .v-slide-item:first-child .v-btn {
//     margin-left: 0;
// }

// .theme--light .notification-btn-toggle .v-slide-item:last-child .v-btn {
//     margin-right: 0;
// }

// .theme--light .notification-btn-toggle .v-btn {
//     border: 1px solid var(--v-primary-base) !important;
//     background-color: #fff !important;
//     color: var(--v-primary-base) !important;
// }

.notification-btn-toggle {
    width: 100%;
}
