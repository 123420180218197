
.map-sheet-actions {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 2;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
