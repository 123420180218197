
// Page Section
.page-section {
    position: relative;
    padding: var(--page-section-padding-y) 0;
    &--first {
        margin-top: calc(var(--page-section-padding-y) * -1);
    }
    &--last {
        margin-bottom: calc(var(--page-section-padding-y) * -1);
    }
    &--xs {
        padding: var(--page-section-padding-y-xs) 0;
    }
    &--sm {
        padding: var(--page-section-padding-y-sm) 0;
        &.page-section {
            &--first {
                padding-top: var(--page-section-padding-y);
            }
            &--last {
                padding-bottom: var(--page-section-padding-y);
            }
        }
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        z-index: -1;
        display: block;
        width: 100vw;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    &.main-section {
        background-color: var(--v-grey-lighten5);
        &:last-child {
            padding-bottom: var(--contents-padding-bottom);
        }
    }
}
