
::v-deep {
    .v-badge {
        &__badge {
            transform: translateY(4px);
        }
    }
    @media (min-width: 1024px) {
        .v-badge {
            &__badge {
                transform: translateY(2px);
                min-width: 14px;
                width: 14px;
                height: 14px;
                font-size: 10px;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
