
.v-expansion-panel {
    &-header {
        padding: 0 !important;
    }
    ::v-deep() {
        .v-expansion-panel-content__wrap {
            padding: 0 0 16px !important;
        }
    }
}
