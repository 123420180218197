
.v-card {
    height: 100%;
    position: relative;
    &__image {
        margin-bottom: -32px;
    }
    &__title {
        padding: var(--grid-gutter-xs);
    }
    &__text {
        p {
            margin-top: 0;
        }
    }
    .ribbon {
        position: absolute;
        top: 0;
        left: 22px;
    }
}
// @media (min-width: 1200px) {
//     .v-card {
//         &::after {
//             content: "";
//             display: block;
//             width: 100%;
//             padding-bottom: 100%;
//         }
//         &__inner {
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//         }
//     }
// }
