
.aside-button-product {
    cursor: pointer;
    position: fixed;
    right: 12px;
    top: calc(50% + 164px);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transform: translateY(40px);
    transition: all ease-out 0.2s;
    display: none;

    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}
.aside-button-item {
    cursor: pointer;
    position: fixed;
    right: 12px;
    top: calc(50% + 164px);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    z-index: 100;
}

@media (min-width: 1024px) {
    .aside-button-product,
    .aside-button-item {
        display: inline-flex;
    }
}
@media (min-width: 1800px) {
    .aside-button-product {
        right: 120px;
    }
}
@media (min-width: 1800px) {
    .aside-button-item {
        right: 200px;
    }
}
