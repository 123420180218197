
// .top-banner-wrapper {
//     margin-top: var(--header-body-height);
// }
.top-banner {
    margin: 0;
    padding: 0;
    &.v-sheet.v-alert:not(.v-sheet--outlined) {
        border-radius: 0 !important;
        box-shadow: none !important;
    }
    ::v-deep {
        .v-alert__content {
            width: 100%;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    // .top-banner-wrapper {
    //     margin: 0;
    // }
}
@media (min-width: 1200px) {
}
