
.product-list {
    border-top: 1px solid var(--border-color);
    margin-top: 10px;
    &__item {
        border-bottom: 1px solid var(--border-color);
        padding: 14px 0;
    }
    max-height: 268px;
    overflow-x: hidden;
    overflow-y: auto;   

    &::-webkit-scrollbar {
        width: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #696969;
    }
    &::-webkit-scrollbar-track {
        background-color: rgba(0,0,0,0.1);
    }
}
