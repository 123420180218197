
.logo {
    position: absolute;
    top: 0;
    left: 12px;
    z-index: 201;
    a {
        display: block;
        background-image: url(/images/logo.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 110px;
        height: var(--header-body-height);
    }
}
@media (min-width: 1024px) {
    .logo {
        position: static;
        a {
            width: 144px;
            height: 40px;
        }
    }
}
