
.app--main {
    ::v-deep {
        #contents {
            padding: 0;
        }
        .v-input--selection-controls .v-input__slot > .v-label,
        .v-input--selection-controls .v-radio > .v-label {
            display: inline-block;
        }
        .v-main {
            padding: 0 !important;
        }
        .v-application--wrap {
            padding-top: var(--header-body-height);
        }
    }
}
@media (min-width: 1024px) {
    .app--main {
        ::v-deep {
            .v-application--wrap {
                padding-top: 0;
            }
        }
    }
}

// top link
.top-link {
    background-color: #ff40ff;
    &__lists {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        &::after {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 100%;
            left: 0;
            background-color: #ff40ff;
        }
    }
    &__list {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            -webkit-transform: translate(0, -50%);
            -ms-transform: translate(0, -50%);
            transform: translate(0, -50%);
            display: block;
            width: 1px;
            height: 12px;
            background-color: rgba(255, 255, 255, 0.5);
        }
        &:first-child::before {
            display: none;
        }
    }
    &__list-link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 36px;
        line-height: 1.2;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        padding: 4px 14px;
        transition: all ease-out 0.2s;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .top-link {
        &__lists {
            padding-left: 52px;
            > .top-link__list:first-child {
                margin-left: -20px;
            }
            &::after {
                display: none;
            }
        }
        &__list-link {
            height: 52px;
            padding: 6px 20px;
        }
    }
}
@media (min-width: 1200px) {
}
@media (max-width: 1024px) {
    .top-link {
        .container {
            padding: 0;
        }
    }
}

.product-list {
    margin-top: 20px;
    border-top: 1px solid var(--v-grey-lighten3);
    max-height: 286px;
    overflow-x: hidden;
    overflow-y: auto;
    // &::-webkit-scrollbar {
    //     width: 2px;
    // }
    // &::-webkit-scrollbar-thumb {
    //     background: var(--v-grey-base);
    // }
    // &::-webkit-scrollbar-track {
    //     background: var(--v-grey-lighten3);
    // }
    &__item {
        padding: 14px 0;
        border-bottom: 1px solid var(--v-grey-lighten3);
    }
}

.category-banner {
    color: #fff !important;
    &__caption {
        text-align: center;
        height: 100%;
        background: linear-gradient(rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%);
        padding: 30px 0;
    }
}
@media (min-width: 1200px) {
    .category-banner {
        &__caption {
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            padding: 90px 70px;
            transition: all ease-out 0.2s;
            opacity: 0;
        }
        &:hover {
            .category-banner__caption {
                background: rgba(0, 0, 0, 0.6);
                opacity: 1;
            }
        }
    }

    .gradient-line {
        display: inline-block;
        width: 100%;
        height: 1px;
        background: linear-gradient(270deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        margin-bottom: 4px;
    }
}
