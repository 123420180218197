
.button-top {
    display: none;
}
@media (min-width: 1024px) {
    .button-top {
        display: flex;
        color: var(--v-primary-base) !important;
        background-color: #fff !important;
        right: 12px;
        top: calc(50% + 234px);
        min-width: 62px !important;
        height: 62px !important;
        z-index: 100;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
        border: 1px solid var(--border-color);
        ::v-deep() {
            .v-btn__content {
                flex-direction: column;
            }
        }

        opacity: 0;
        visibility: hidden;
        transform: translateY(40px);
        transition: all ease-out 0.2s;
    }
    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}
@media (min-width: 1800px) {
    .button-top {
        right: 120px;
    }
}
