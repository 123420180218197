
.contents-card-advertisement {
    &__image {
        position: relative;
        .v-chip {
            position: absolute;
            top: 16px;
            right: 16px;
        }
    }
}
