
// main-visual
.main-visual {
    width: 100%;
    &__inner {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 1920px;
        width: 100%;
        height: 500px;
        margin: auto;
    }
    &__img {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        max-width: calc(100% + 2px);
        width: calc(100% + 2px);
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: rgba(0, 0, 0, 0.25);
    }
    .container {
        z-index: 1;
    }
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .main-visual {
        &__inner {
            height: 700px;
        }
    }
}
@media (min-width: 1024px) {
    .main-visual {
        &__inner {
            height: 600px;
        }
    }
}

.swiper-control {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 60px;
    z-index: 1;
    display: flex;
    align-items: center;
    .swiper-button-prev,
    .swiper-button-next {
        position: relative;
        width: initial;
        width: 64px;
        height: 64px;
        margin-top: initial;
        top: initial;
        left: initial;
        right: initial;
        &::after {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            font-size: 32px;
            color: #fff;
        }
    }
    .swiper-button-divider {
        width: 1px;
        height: 100%;
        max-height: 40px !important;
        background: var(--border-color);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%);
    }
}
